import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import AppIcon from "../../static/images/AppIcon@2x.png"

const AppIconImage = styled.img`
    width: 40px;
    vertical-align: middle;
    padding-right: 10px;
`

const AppIconLink = styled(Link)`
    color: white;
    &:hover {
        color: white;
        text-decoration: underline;
    }
`

export default () => (
    <AppIconLink to="/" title={"Sashimi"}>
        <AppIconImage alt={"Sashimi logo"} src={AppIcon} />
        SASHIMI
    </AppIconLink>
)