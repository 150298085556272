import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from "@emotion/styled"

import { Flex, Box } from 'reflexbox'

import AppHomeLink from "./app-link"

import ContactSupport from "../../static/images/ContactSupport.svg"
import ContactSupportHighlight from "../../static/images/ContactSupportHighlight.svg"
import ContactSupportActive from "../../static/images/ContactSupportActive.svg"


const StyledHeader = styled.header`
    position: absolute;
    overflow: auto;
    top: 0;
    width: 100%;

    display: block;
    padding-top: 29px;
`

const NavBar = styled(Flex)`
    width: 100%;
    padding-left: 34px;
    padding-right: 34px;
`

const SupportLink = styled.a`
    background: url('${ContactSupport}');
    background-repeat: none;
    width: 141px;
    height: 40px;
    &:hover {
        background: url('${ContactSupportHighlight}')    
    }
    &:active {
        background: url('${ContactSupportActive}')    
    }
`

const Header = ({siteTitle}) => (
    <StyledHeader>

        <NavBar
            px={2}
            color='white'
            alignItems='center'>

            <Box p={2} fontWeight='bold'>
                <AppHomeLink />
            </Box>
            <Box mx='auto' />
            <SupportLink variant='nav' href={"https://support.sashimiapp.com"} />
        </NavBar>
    </StyledHeader>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
