/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"

import AppHomeLink from "../components/app-link"

import "../../static/styles/main.scss"
import logo from "../../static/images/logo.svg"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconFacebook from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"

import Header from "../components/header"

const Container = styled.div`
    position: relative;
`

const Tagline = styled.div`
    padding-top: 5px;
    padding-left: 45px;
    max-width: 280px;
`

const FooterHeading = styled.h4`
    color: rgba(255,255,255,0.5);
`

const Layout = ({children}) => (
    <StaticQuery query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `} render={data => (
        <>
            <Global
                styles={css`
                    body {
                        background-color: #2C2C2C;
                    }
                `}
            />
            
            <main>
                <Container>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    {children}
                </Container>
            </main>
            <footer>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-5"}>
                            <div className={"widget__item"}>
                                <div className={"logo"}>
                                    <AppHomeLink />
                                </div>

                                <Tagline>{data.site.siteMetadata.description}</Tagline>
                            </div>
                        </div>

                        <div className={"col-2"}>
                            <div className={"widget__item"}>
                                <ul className={"links"}>
                                    <FooterHeading>Learn more</FooterHeading>
                                    <ul>
                                        <li><Link to="/about" title={"About Us"}>About us</Link></li>
                                        <li><a href={"https://histaff.io/blog"}>Blog</a></li>
                                    </ul>
                                </ul>
                            </div>
                        </div>

                        <div className={"col-2"}>
                            <div className={"widget__item"}>
                                <div className={"links"}>
                                    <FooterHeading>Support</FooterHeading>
                                    <ul>
                                        <li><Link to="/contact" title={"Contact Us"}>Contact support</Link></li>
                                        <li><Link to="/contact" title={"Contact Us"}>Manual</Link></li>
                                        <li><Link to="/privacy" title={"Privacy Policy"}>Privacy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={"col-3"}>
                            <div className={"widget__item"}>
                                <div className={"social"}>
                                    <a href="https://twitter.com/sashimiapp" target={"_blank"} title={"Twitter"}><img alt={"Twitter"} src={iconTwitter}/></a>
                                    <a href="https://facebook.com/sashimiapp" target={"_blank"} title={"Facebook"}><img alt={"Facebook"} src={iconFacebook}/></a>
                                    <a href="https://github.com/sashimiapp" target={"_blank"} title={"GitHub"}><img alt={"GitHub"} src={iconGitHub}/></a>
                                    <a href="https://www.instagram.com/sashimiapp/" target={"_blank"} title={"Instagram"}><img alt={"Instagram"} src={iconInstagram}/></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"copyright"}>
                        <p>Copyright {new Date().getFullYear()}, {` `} <a href="https://sashimiapp.com" title={"Sashimi"}>Cosmic Jetpack Oy &amp; Lumoa Ltd</a>. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    )}/>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
